.nav-tabs {
    /* override based on design */
    .nav-link {
        border-top: none;
        border-right: none;
        border-left: none;
        line-height: 1.428em;
        padding: 1em;

        &.active {
            border-bottom-width: 2px;
        }
    }
}
