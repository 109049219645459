.ftc-modal {
    --modal-header-padding-y: 32px;
    --modal-header-padding-x: 40px;
    --modal-padding-y: var(--ftc-modal-padding-y, 40px);
    --modal-padding-x: var(--ftc-modal-padding-x, 40px);
    --modal-padding-bottom: var(--ftc-modal-padding-bottom, 24px);
    --bs-modal-border-radius: var(--ftc-modal-border-radius, 24px);
    --modal-gap: var(--ftc-modal-gap, 24px);

    .modal-title {
        display: block;
        width: 100%;
        font-size: 18px;
    }

    .modal-header {
        position: relative;
        background: #d0dde7;
        min-height: 96px;
        padding: var(--modal-header-padding-y) var(--modal-header-padding-x);
        border-radius: var(--bs-modal-border-radius) var(--bs-modal-border-radius) 0 0;

        &.ftc-alert {
            min-height: 40px;
            padding: 0;
            background: white;
            border-bottom: none;
        }

        button.close {
            width: 24px;
            height: 24px;
            border: none;
            background-color: transparent;
            color: var(--primary-black-70, #ababab);
            position: absolute;
            top: 20px;
            right: 20px;
            padding: 0;
        }
    }

    @mixin is-border-bottom-radius {
        border-bottom-left-radius: var(--bs-modal-border-radius);
        border-bottom-right-radius: var(--bs-modal-border-radius);
    }

    .modal-content {
        border: none;
        box-shadow: 0 2px 16px 0 rgb(0 0 0 / 16%);
    }

    .modal-body {
        &.ftc-modal-body {
            background-color: white;

            --bs-modal-padding: var(--modal-padding-y) var(--modal-padding-x);

            &.is-border-bottom-radius {
                @include is-border-bottom-radius;
            }
        }

        &.ftc-alert {
            display: flex;
            flex-direction: column;
            gap: var(--modal-gap);

            --bs-modal-padding: 0 var(--modal-padding-bottom) var(--modal-padding-x);

            .alert-title {
                color: var(--gray-gray-700, #2d3748);
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
            }

            .alert-content {
                color: var(--primary-black-0, #000);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
            }

            .alert-btn {
                display: flex;
                gap: var(--modal-gap);
                justify-content: right;
            }
        }

        .plain-text {
            color: #474747;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
        }

        .details {
            > div {
                padding-bottom: 15px;
            }
        }
    }

    .modal-footer {
        background-color: white;
        border-top: none;
        gap: var(--ftc-modal-footer-buttons-gap);

        &.is-border-bottom-radius {
            padding: 0 var(--modal-padding-x) var(--modal-padding-y) var(--modal-padding-x);

            @include is-border-bottom-radius;
        }

        &:not(.is-border-bottom-radius) {
            padding-top: var(--modal-padding-y);
        }
    }

    // extend xxl size
    @media (width >= 576px) {
        &.modal-xxl {
            --bs-modal-width: 90%;
        }
    }

    @media (width >= 1750px) {
        &.modal-xxl {
            --bs-modal-width: 1673px;
        }
    }
}
