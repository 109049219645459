.breadcrumb {
    --bs-breadcrumb-item-padding-x: 12px;
    --bs-breadcrumb-item-active-color: var(--primary-black-0, '#000');

    .breadcrumb .breadcrumb-item {
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
    }
}
