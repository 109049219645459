#tree-view-page {
    nav {
        --tree-node-height: 112px;
        --tree-node-width: 514px;
        --tree-node-image: 64px;
        --space-between-node: 48px;
        --indent-unit: 130px;

        margin: 0 auto;
        width: auto;
        min-height: auto;
        left: 25%;
        position: relative;
    }

    &.dropdown-mode nav {
        --dropdown-mode-indent-unit: 50px;

        height: calc(100vh - 200px);
        overflow: auto;
        left: unset;
        margin-top: 20px;
    }

    ul {
        text-align: left;
    }

    ul.list,
    ul.list ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    ul.list ul {
        position: relative;
        margin-left: var(--indent-unit);
    }

    ul.list ul::before {
        content: '';
        display: block;
        position: absolute;
        left: calc(var(--indent-unit) / 2);
        bottom: 0;
        width: calc(112px / 2);
        top: calc(0% - var(--space-between-node));
        border-left: 3px dashed var(--primary-black-60, #8f8f8f);
        height: 100%;
    }

    ul.list li {
        position: relative;
        margin: 0;
    }

    ul.list li a {
        position: relative;
    }

    ul.list ul li::before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 66px;
        width: 66px;
        height: 0;
    }

    ul.list ul li:last-child::before {
        bottom: 0;
        border-bottom: 3px dashed var(--primary-black-60, #8f8f8f);
        top: -48px;
        border-radius: 0 0 0 14px;
        display: block;
        height: 112px;
        z-index: 2;
    }

    .leaf {
        &.clickable:hover {
            border: 6px solid var(--secondary-blue-50, #2b7ce1);
            padding: 18px 26px;
        }

        display: inline-grid;
        width: var(--tree-node-width);
        height: var(--tree-node-height);
        border-radius: 24px;
        box-shadow: 0 6px 30px 0 rgb(0 0 0 / 10%);
        padding: 24px 32px;
        gap: 24px;
        align-items: center;
        grid-template-columns: 64px auto 48px;
        margin-bottom: var(--space-between-node);
        margin-left: var(--indent-unit);
        z-index: 10;
        position: relative;
        background: #fff;

        &.disabled {
            background: #e3e1e1;
        }

        .tree-node-card-icon {
            img {
                width: 64px;
                height: 64px;
            }
        }

        .tree-node-card-content {
            dl {
                margin: 0;
                padding: 0;
                display: inline-grid;
                gap: 8px;

                dt,
                dd {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }

                dt {
                    color: #000;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 32px; /* 160% */
                }

                dd {
                    color: var(--primary-black-60, #8f8f8f);
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 32px;
                    margin: 0;
                    padding: 0;
                }
            }
        }

        .tree-node-caret {
            width: 48px;
            height: 48px;

            // font-size: 48px;
            span {
                font-size: inherit;
            }
        }
    }

    &.dropdown-mode > nav > ul.list > li > .leaf {
        margin-left: var(--dropdown-mode-indent-unit);
    }

    .custom-collapse ul.list:last-of-type {
        > li {
            &::after {
                // !mask on left line for last child!
                content: ' ';
                position: absolute;
                width: 66px;
                height: calc(100% - 48px - 3px);
                left: 65px;
                background: var(--fpc-body-background);
                top: calc(112px / 2);
                z-index: 1;
            }

            &::before {
                border-bottom: 3px dashed var(--primary-black-60, #8f8f8f);
                top: -48px;
                border-radius: 0 0 0 14px;
                display: block;
                height: 112px;
                z-index: 2;
            }
        }
    }

    &.dropdown-mode .custom-collapse ul.list:last-of-type {
        > li {
            &::after {
                // !mask on left line for last child!
                background: #fff;
            }
        }
    }

    &.dropdown-mode > nav > ul.list > li > .custom-collapse > ul.list {
        margin-left: var(--dropdown-mode-indent-unit);
    }
}
