/* Sizing */

.btn {
    --bs-btn-disabled-opacity: 0.3;

    padding: 6px 1rem;
    line-height: 24px;
    font-weight: 500;
    font-size: var(--ftc-base-font-size);
    border-radius: 24px;

    svg {
        path {
            fill: var(--bs-btn-color);
            stroke: var(--bs-btn-color);
        }
    }

    svg,
    [class^='material-symbols-'] {
        vertical-align: bottom;
        margin-right: 3px;
        line-height: 24px;
    }

    &:not(.btn-link) {
        min-width: 115px;
    }

    &:hover {
        svg {
            path {
                fill: var(--bs-btn-hover-color);
                stroke: var(--bs-btn-hover-color);
            }
        }
    }

    &.btn-icon-only {
        svg,
        [class^='material-symbols-'] {
            margin-right: 0;
        }
    }
}

.btn-lg {
    line-height: 20px;
    border-radius: 32px;
    padding: 14px 20px;
}

.btn-sm {
    line-height: 20px;
    border-radius: 32px;
    padding: 4px 16px;
}

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: var(--secondary-blue-50, #2b7ce1);
    --bs-btn-border-color: var(--secondary-blue-50, #2b7ce1);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--secondary-blue-40, #2b7ce1);
    --bs-btn-hover-border-color: var(--secondary-blue-40, #2b7ce1);
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--secondary-blue-40, #2b7ce1);
    --bs-btn-active-border-color: var(--secondary-blue-40, #2b7ce1);
    --bs-btn-active-shadow: inset 0 3px 5px rgb(0 0 0 / 12.5%);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: var(--secondary-blue-50, #2b7ce1);
    --bs-btn-disabled-border-color: var(--secondary-blue-50, #2b7ce1);
}

.btn-outline-primary {
    --bs-btn-color: var(--secondary-blue-50, #2b7ce1);
    --bs-btn-border-color: var(--secondary-blue-50, #2b7ce1);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--secondary-blue-50, #2b7ce1);
    --bs-btn-hover-border-color: var(--secondary-blue-50, #2b7ce1);
    --bs-btn-focus-shadow-rgb: 13, 110, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--secondary-blue-50, #2b7ce1);
    --bs-btn-active-border-color: var(--secondary-blue-50, #2b7ce1);
    --bs-btn-active-shadow: inset 0 3px 5px rgb(0 0 0 / 12.5%);
    --bs-btn-disabled-color: var(--secondary-blue-50, #2b7ce1);
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: var(--secondary-blue-50, #2b7ce1);
    --bs-gradient: none;
}

.btn-danger {
    --bs-btn-color: #fff;
    --bs-btn-bg: var(--tertiary-red-50, #d8281c);
    --bs-btn-border-color: var(--tertiary-red-50, #d8281c);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--tertiary-red-40, #d8281c);
    --bs-btn-hover-border-color: var(--tertiary-red-40, #d8281c);
    --bs-btn-focus-shadow-rgb: 225, 83, 97;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--tertiary-red-40, #d8281c);
    --bs-btn-active-border-color: var(--tertiary-red-40, #d8281c);
    --bs-btn-active-shadow: inset 0 3px 5px rgb(0 0 0 / 12.5%);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: var(--tertiary-red-50, #d8281c);
    --bs-btn-disabled-border-color: var(--tertiary-red-50, #d8281c);
}

.btn-outline-danger {
    --bs-btn-color: var(--tertiary-red-50, #d8281c);
    --bs-btn-border-color: var(--tertiary-red-50, #d8281c);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--tertiary-red-50, #d8281c);
    --bs-btn-hover-border-color: var(--tertiary-red-50, #d8281c);
    --bs-btn-focus-shadow-rgb: 220, 53, 69;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--tertiary-red-50, #d8281c);
    --bs-btn-active-border-color: var(--tertiary-red-50, #d8281c);
    /* stylelint-disable-next-line color-function-notation */
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 12.5%);
    --bs-btn-disabled-color: var(--tertiary-red-50, #d8281c);
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: var(--tertiary-red-50, #d8281c);
    --bs-gradient: none;
}

.btn-success {
    --bs-btn-color: #fff;
    --bs-btn-bg: var(--highlight-green-50, #34b67a);
    --bs-btn-border-color: var(--highlight-green-50, #34b67a);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--highlight-green-40, #34b67a);
    --bs-btn-hover-border-color: var(--highlight-green-40, #34b67a);
    --bs-btn-focus-shadow-rgb: 225, 83, 97;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--highlight-green-40, #34b67a);
    --bs-btn-active-border-color: var(--highlight-green-40, #34b67a);
    --bs-btn-active-shadow: inset 0 3px 5px rgb(0 0 0 / 12.5%);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: var(--highlight-green-50, #34b67a);
    --bs-btn-disabled-border-color: var(--highlight-green-50, #34b67a);
}

.btn-outline-success {
    --bs-btn-color: var(--highlight-green-50, #34b67a);
    --bs-btn-border-color: var(--highlight-green-50, #34b67a);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--highlight-green-50, #34b67a);
    --bs-btn-hover-border-color: var(--highlight-green-50, #34b67a);
    --bs-btn-focus-shadow-rgb: 25, 135, 84;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--highlight-green-50, #34b67a);
    --bs-btn-active-border-color: var(--highlight-green-50, #34b67a);
    /* stylelint-disable-next-line color-function-notation */
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 12.5%);
    --bs-btn-disabled-color: var(--highlight-green-50, #34b67a);
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: var(--highlight-green-50, #34b67a);
    --bs-gradient: none;
}

.btn-secondary {
    --bs-btn-color: #fff;
    --bs-btn-bg: var(--primary-black-60, #8f8f8f);
    --bs-btn-border-color: var(--primary-black-60, #8f8f8f);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--primary-black-50, #8f8f8f);
    --bs-btn-hover-border-color: var(--primary-black-50, #8f8f8f);
    --bs-btn-focus-shadow-rgb: 130, 138, 145;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--primary-black-50, #8f8f8f);
    --bs-btn-active-border-color: var(--primary-black-50, #8f8f8f);
    /* stylelint-disable-next-line color-function-notation */
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 12.5%);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: var(--primary-black-60, #8f8f8f);
    --bs-btn-disabled-border-color: var(--primary-black-60, #8f8f8f);
}

.btn-outline-secondary {
    --bs-btn-color: var(--primary-black-60, #8f8f8f);
    --bs-btn-border-color: var(--primary-black-60, #8f8f8f);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--primary-black-60, #8f8f8f);
    --bs-btn-hover-border-color: var(--primary-black-60, #8f8f8f);
    --bs-btn-focus-shadow-rgb: 108, 117, 125;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--primary-black-60, #8f8f8f);
    --bs-btn-active-border-color: var(--primary-black-60, #8f8f8f);
    /* stylelint-disable-next-line color-function-notation */
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 12.5%);
    --bs-btn-disabled-color: var(--primary-black-60, #8f8f8f);
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: var(--primary-black-60, #8f8f8f);
    --bs-gradient: none;
}

.btn-link {
    --bs-btn-font-weight: 500;
    --bs-btn-color: var(--secondary-blue-50, #2b7ce1);
    --bs-btn-bg: transparent;
    --bs-btn-border-color: transparent;
    --bs-btn-hover-color: var(--secondary-blue-50, #2b7ce1);
    --bs-btn-hover-border-color: transparent;
    --bs-btn-active-color: var(--secondary-blue-40, #2b7ce1);
    --bs-btn-active-border-color: transparent;
    --bs-btn-disabled-color: var(--primary-black-60, #8f8f8f);
    --bs-btn-disabled-border-color: transparent;
    --bs-btn-box-shadow: 0 0 0 #000;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;

    text-decoration: none;
    padding: 6px 4px;
    line-height: 24px;
    font-size: var(--ftc-base-font-size, 14px);
    font-weight: 400;
    border: none;

    &.btn-link-danger {
        --bs-btn-color: var(--tertiary-red-50, #d8281c);
        --bs-btn-hover-color: var(--tertiary-red-50, #d8281c);
        --bs-btn-active-color: var(--tertiary-red-40, #b3261e);
    }

    &.btn-link-success {
        --bs-btn-color: var(--highlight-green-50, #34b67a);
        --bs-btn-hover-color: var(--highlight-green-50, #34b67a);
        --bs-btn-active-color: var(--highlight-green-40, #26aa6d);
    }
}
