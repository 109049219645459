#world-background {
    background-image: url('../../../../public/media/world_map.png');
    background-repeat: no-repeat;
    background-size: 200px 110px;
    background-position: top 20px left 45%;
    padding: 65px 0;
}

#title {
    text-align: center;
    font-size: 20px;
    color: #5b6e83;
    margin: 30px auto;
}

#option-section {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    width: 250px;
    margin: auto;
}

.option {
    width: 100%;
    margin: 10px 0;
    cursor: pointer;
}

.half-width {
    width: 50%;
    margin: 10px 0;
    cursor: pointer;
}
