.ftc-search-bar {
    position: relative;
    display: inline-flex;

    input {
        border-radius: 28px;
        background: var(--primary-black-95, #f2f2f2);
        border: none;
        font-weight: 500;

        // padding: 12px 52px 12px 16px;
        padding: 8px 52px 8px 16px; // make height to 40px, same as button
        outline: none;

        &::placeholder {
            color: var(--primary-black-60, #8f8f8f);
        }

        &::-webkit-search-cancel-button {
            display: none;
        }

        + .ftc-search-bar-icon {
            display: flex;
            width: 40px;
            height: 100%;
            position: absolute;
            right: 12px;
            top: 0;
            align-items: center;
            justify-content: center;
            color: #49454f;
            font-size: 24px;
        }
    }
}
