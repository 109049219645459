/* stylelint-disable no-descending-specificity */
.navigation {
    --font-color: #fff;
    --menu-active: #d8281c;
    --menu-item-padding-y: 14px;
    --menu-item-offset-left: 16px;
    --font-weight: 400;

    background: #3f85bb;
    height: 100%;
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &,
    a {
        font-size: var(--ftc-base-font-size, 14px);
        font-weight: var(--font-weight);
        line-height: 24px;
        color: var(--font-color);
        text-decoration: none;
    }

    ul {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;

        [class^='icon'],
        [class^='material-symbols-outlined'] {
            min-width: 24px;
            min-height: 24px;
            display: inline-block;
            margin-right: 12px;
        }

        a,
        button {
            display: flex;
            align-items: center;
            padding: 10px var(--menu-item-padding-y) 10px
                calc(var(--menu-item-offset-left) + var(--menu-item-padding-y));
            font-weight: var(--font-weight);
        }

        a:hover {
            color: var(--font-color);
        }

        li {
            list-style: none;

            a {
                z-index: 2;

                // level1 active

                &.active {
                    position: relative;

                    &::before {
                        content: ' ';
                        background-image: url('data:image/svg+xml,<svg width="216" height="120" viewBox="0 0 216 120" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M181.772 33.4118C156.121 34.5813 66.8346 34.8694 23.6935 34.9404C10.6057 34.9619 0 45.5738 0 58.6616C0 71.7031 10.5376 82.295 23.5789 82.375C66.6763 82.6393 156.099 83.2867 181.772 84.2353C216 85.5 216 120 216 120V0C216 0 216 31.8512 181.772 33.4118Z" fill="%23F8F9FA"/></svg>');
                        color: var(--menu-active);
                        background-position: center right;
                        width: 100%;
                        height: 216px;
                        background-repeat: no-repeat;
                        position: absolute;
                        right: 0;
                        top: auto;
                        pointer-events: none;
                    }

                    color: var(--menu-active);
                    font-weight: 500;

                    > * {
                        z-index: 1;
                    }
                }

                &.disabled {
                    color: #b0b0b0;
                    pointer-events: none;
                }
            }
        }

        ul {
            ul {
                a {
                    // level 3
                    padding-left: calc(var(--menu-item-padding-y) + 70px) !important;
                }
            }

            li {
                a {
                    // level 2
                    padding-left: calc(var(--menu-item-padding-y) + 50px);

                    &:only-child {
                        // level 2 and it doesn't have nest menu
                        padding-left: calc(var(--menu-item-padding-y) + 50px);

                        &.active {
                            &::before {
                                background-image: url('data:image/svg+xml,<svg width="194" height="120" viewBox="0 0 194 120" fill="none" xmlns="http://www.w3.org/2000/svg"><path id="Rectangle 45" d="M163.258 33.4118C140.681 34.5579 63.2111 34.8575 23.6532 34.9359C10.5617 34.9618 0 45.5768 0 58.6683C0 71.7082 10.4815 82.3007 23.5212 82.3911C63.0385 82.665 140.656 83.3055 163.258 84.2353C194 85.5 194 120 194 120V0C194 0 194 31.8512 163.258 33.4118Z" fill="%23F8F9FA"/></svg>');
                                height: 120px;
                            }
                        }
                    }

                    &.disabled {
                        color: #b0b0b0;
                        pointer-events: none;
                    }
                }

                // a:not(:only-child) {
                //     // Notification
                //     border: 2px solid green;
                // }
            }
        }

        button {
            background-color: inherit;
            border: none;
            color: inherit;
            margin: unset;
            width: 100%;
        }
    }

    .collapse-btn {
        position: relative;

        &::after {
            /* stylelint-disable font-family-no-missing-generic-family-keyword */
            font-family: 'Material Symbols Outlined';
            content: '\e5c5';
            display: inline-block;
            padding-left: 4px;
        }

        &.active {
            background-color: #5699cc;

            &::after {
                content: '\e5c7';
            }
        }
    }

    .collapse-ul {
        display: none;

        &.show {
            display: block;
        }
    }

    hr {
        border-top: 1px solid #94c3ff;
        opacity: 1;
        margin: 24px var(--menu-item-offset-left);

        &.site-divider {
            margin: 12px var(--menu-item-offset-left);
        }
    }

    .info-list {
        gap: 20px;
        padding: 0 16px;

        li {
            padding: 0 14px;
        }
    }

    dl {
        margin: 0;
        display: flex;
        flex-direction: row;

        dt {
            span {
                margin-right: 8px;
                display: inline-flex;
            }

            margin-right: 12px;
            font-weight: normal;
            font-style: initial;
            display: flex;
            align-items: center;
        }

        dd {
            margin: 0;
            font-size: var(--ftc-base-font-size, 14px);
            font-style: normal;
            font-weight: var(--font-weight);
            line-height: 24px; /* 150% */
        }
    }

    .power-by {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 12px;
        font-style: normal;
        font-weight: var(--font-weight);
        line-height: 20px; /* 166.667% */
        margin-top: 30px;
        padding: 0 30px;

        img {
            width: 26px;
            height: 18px;
        }

        sup {
            vertical-align: super;
        }
    }
}
