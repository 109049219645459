.spinner-border {
    &.ftc-loader {
        --bs-spinner-width: 7rem;
        --bs-spinner-height: 7rem;
        --bs-spinner-border-width: 15px;

        border: 15px solid #94c3ff80;
        border-left: 15px solid #94c3ff !important;
    }

    &.inline-loader {
        --bs-spinner-width: 1.5rem;
        --bs-spinner-height: 1.5rem;
        --bs-spinner-border-width: 3px;

        border: 3px solid #94c3ff80;
        border-left: 3px solid #94c3ff !important;
    }
}
