.ftc-table {
    // override display from table to block, to make col can auto adjust width to avoid scroll bar
    & > div > div {
        display: block;
    }
    .rdt_Table {
        color: #000;
        font-family: Inter;
        font-size: var(--ftc-base-font-size, 14px);
        font-style: normal;
        font-weight: 400;
        line-height: 20px;

        .form-check-input[type='checkbox'] {
            border-radius: 0.25em;
            border: 2px solid #757575;
            width: 20px;
            height: 20px;
            background-color: #fff;
            cursor: pointer;

            &:checked {
                background-color: var(--secondary-blue-50, '#2b7ce1');
                border-color: var(--secondary-blue-50, '#2b7ce1');
            }
        }

        .rdt_TableHeadRow {
            min-height: 48px;
        }

        .rdt_TableHead {
            color: var(--shades-navy-90, #a1adc1);
            font-family: inherit;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px; /* 133.333% */
            text-transform: uppercase;
        }

        .rdt_TableCell {
            padding: 6px 8px;
            color: #000;
            font-family: inherit;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;

            [class^='material-symbols-'] {
                font-size: 20px; // default
            }
        }
    }

    .rdt_TableCol {
        font-weight: 600;
        padding-left: 8px;
        padding-right: 8px;
    }

    .rdt_Pagination {
        color: inherit;
        font-size: inherit;
        line-height: inherit;
        font-weight: 500; // medium

        select {
            border-bottom: 1px solid #757575;
        }

        #pagination-first-page,
        #pagination-previous-page,
        #pagination-last-page,
        #pagination-next-page {
            fill: var(--secondary-blue-50, '#2b7ce1');

            &[aria-disabled='true'] {
                fill: #c4cdd5;
            }
        }
    }
}
