#layout {
    display: grid;
    grid-template-rows: auto 1fr auto;
    min-height: 100vh;

    // .layout-header {}
    .layout-main {
        border-radius: 16px;
        overflow: hidden;
        margin: var(--ftc-layout-main-margin, 32px 56px);
        background-color: #f8f9fa;
        max-width: var(--ftc-layout-max-width);

        @media screen and (width >= 3840px) {
            width: 100%;
        }

        .layout-main-cnt {
            display: grid;
            height: 100%;

            // grid-template-columns: var(--ftc-navigation-width, 233px) minmax(0, max-content);
            grid-template-columns: var(--ftc-navigation-width, 233px) calc(100% - var(--ftc-navigation-width, 233px));

            // .layout-main-cnt-left {}
            .layout-main-cnt-right {
                padding: 40px 45px;
            }
        }
    }

    .layout-footer {
        margin: 0 auto 55px;
    }

    .content-wall {
        border-radius: 16px;
        background: #fff;
        box-shadow: 0 0 6px 0 rgb(173 192 211 / 25%);
        padding: 24px;
    }
}
