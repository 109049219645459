.account-card {
    border-radius: 24px;
    background: #fff;
    height: 242px;
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    border: none;
    box-sizing: border-box;
    box-shadow: 0 6px 30px 0 rgb(0 0 0 / 10%);
    padding: 0;
    position: relative;

    .logined {
        border-radius: 32px;
        background: #2b7ce1;
        padding: 6px 16px;
        position: absolute;
        right: 10px;
        top: 8px;
        color: #fff;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
    }

    .account-card-top {
        @media (width < 1408px) {
            display: none;
        }

        background: var(--secondary-blue-99, #f7fcff);
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 100px;
        flex-flow: row wrap;

        svg {
            width: 64px;
            height: 64px;
        }

        img {
            width: 144px;
            height: 144px;
        }
    }

    &.active {
        border: 6px solid #2b7ce1;
    }

    .account-card-bottom {
        padding: 40px 24px;
        flex-grow: 1; // match the remaining width
        min-width: 0;
        height: 100%;

        dl {
            display: flex;
            flex-direction: row;
            gap: 24px;
            margin-bottom: 24px;

            &:last-of-type {
                margin-bottom: 0;
            }

            dt {
                color: #333;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                width: 105px;
                text-align: right;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            dd {
                color: #000;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 22px; /* 122.222% */
                margin: 0 auto;
                width: calc(100% - 105px);
                text-align: left;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        .master-badge {
            padding: 2px 8px;
            border-radius: 40px;
            background: #d8281c;
            color: #fff;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
        }
    }
}
