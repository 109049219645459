#account-selection-page {
    --account-card-nav-width: 80px;

    display: grid;
    padding: 32px;
    grid-template-rows: auto 1fr;

    &.account-selection-fullpage {
        height: 100%;
        min-height: 100vh;
    }

    .container-fix {
        @media (width >= 1617px) {
            max-width: calc(1593px + 24px);
        }
    }

    .account-selection-list {
        text-align: center;
        cursor: pointer;
        color: #535353;
        list-style: none;
    }

    .welcome {
        margin-bottom: 40px;

        h1 {
            font-size: 32px;
            font-weight: 700;
            line-height: 48px;
            margin-bottom: 24px;
        }
    }

    main {
        width: 100%;
        text-align: center;
    }

    .content-header {
        margin: 82px auto;
    }

    #account-select-prev {
        left: -80px;
    }

    #account-select-next {
        right: -80px;
    }

    #account-select-prev,
    #account-select-next {
        position: absolute;
        width: var(--account-card-nav-width);
        height: var(--account-card-nav-width);
        border: none;
        background: transparent;
        top: 50%;
        margin-top: -calc(var(--account-card-nav-width) / 2);
        padding: 0;
        z-index: 1;
        color: var(--primary-black-80, #c7c7c7);

        &:hover {
            color: var(--secondary-blue-80, #69acff);
        }

        &:disabled {
            color: #e3e1e1;
        }

        span {
            font-size: 80px;
        }
    }

    .pagination {
        --bs-pagination-padding-x: 0;
        --bs-pagination-padding-y: 0;
        --bs-pagination-font-size: 24px;
        --bs-pagination-color: #fff;
        --bs-pagination-bg: var(--primary-black-80, #c7c7c7);
        --bs-pagination-border-width: 0;
        --bs-pagination-border-color: transparent;
        --bs-pagination-border-radius: 40px;
        --bs-pagination-hover-color: #fff;
        --bs-pagination-hover-bg: var(--secondary-blue-50, #2b7ce1);
        --bs-pagination-hover-border-color: var(--bs-border-color);
        --bs-pagination-focus-color: var(--secondary-blue-50, #2b7ce1);
        --bs-pagination-focus-bg: var(--bs-secondary-bg);
        --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
        --bs-pagination-active-color: #fff;
        --bs-pagination-active-bg: var(--secondary-blue-50, #2b7ce1);
        --bs-pagination-active-border-color: var(--secondary-blue-50, #2b7ce1);
        --bs-pagination-disabled-color: var(--bs-secondary-color);
        --bs-pagination-disabled-bg: var(--bs-secondary-bg);
        --bs-pagination-disabled-border-color: var(--bs-border-color);

        font-weight: 700;
        line-height: 36px;
        gap: 32px;

        .page-link {
            min-width: 40px;
            min-height: 40px;
            border-radius: 40px;
            text-align: center;
            line-height: 40px;
        }
    }
}

$search-input-margin-right: 34px;

#account-selection-popup-header {
    #search-input {
        margin-right: $search-input-margin-right;
    }
}

#account-selection-dropdown {
    width: 755px;

    header {
        background: #d0dde7;
        min-height: 96px;
        padding: var(--bs-modal-padding, 1rem) 40px;
        position: relative;

        button.close {
            width: 24px;
            height: 24px;
            border: none;
            background-color: transparent;
            color: var(--primary-black-70, #ababab);
            position: absolute;
            top: 20px;
            right: 20px;
            padding: 0;
        }

        #search-input {
            margin-right: $search-input-margin-right;
        }
    }
}
