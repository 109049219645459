footer {
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-self: center;
    gap: 60px;

    ul {
        margin: 0;
        padding: 0;
        display: inline-flex;

        li {
            display: inline-block;

            a {
                display: block;
            }

            &:not(:last-child) {
                a {
                    &::after {
                        content: '|';
                        margin: 0 4px;
                        display: inline-block;
                    }
                }
            }
        }
    }
}
