// import Fortinet Design Libaray
@import './fortilib/colors';

// import bootstrap 5 related
@import 'bootstrap/bs_includes';
@import 'bootstrap/bs_form';
@import 'bootstrap/bs_buttons';
@import 'bootstrap/bs_nav';
@import 'bootstrap/bs_breadcrumb';
@import 'bootstrap/bs_badge';
@import 'bootstrap/bs_colors';
@import 'bootstrap/bs_dropdown';
@import 'bootstrap/bs_tooltip';
@import 'bootstrap/bs_spinner';

:root {
    --fpc-body-background: #e2ecf4;
    --ftc-base-font-size: 14px;
    --ftc-navigation-width: 233px;
    --ftc-layout-max-width: 100%;
    --ftc-layout-main-margin: 32px 36px;
    --ftc-input-font-size: 14px;
    --ftc-input-text: #322f35;
    --ftc-input-border-radius: 4px;

    // modal
    --ftc-modal-padding-bottom: 24px;
    --ftc-modal-border-radius: 24px;
    --ftc-modal-gap: 24px;
    --ftc-modal-footer-buttons-gap: 24px;
    --ftc-modal-padding-y: 40px;
    --ftc-modal-padding-x: 40px;
}

.modal {
    background: rgba(55 55 55 / 60%);
    backdrop-filter: blur(10px);
}

html {
    /* please don't change this valuse, it's unit of rem */
    font-size: 16px;
}

body {
    // as discussed, we support laptop/desktop at first.
    min-width: 1480px;
    background: var(--fpc-body-background);
    font-size: var(--ftc-base-font-size);
}

a {
    color: var(--secondary-blue-50, #2b7ce1);

    &:hover {
        color: var(--secondary-blue-40, #186cd6);
    }
}

@media screen and (width >= 1920px) {
    :root {
        --ftc-layout-main-margin: 32px 56px;
    }
}

@media screen and (width >= 3840px) {
    :root {
        --ftc-navigation-width: 470px;
        --ftc-layout-main-margin: 32px auto;
        --ftc-layout-max-width: 2694px;
    }
}

.page-h1 {
    color: var(--gray-gray-700, #2d3748);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    margin-bottom: 32px;
}

.log-filters-dropdown-menu {
    .filter-item-list {
        min-width: 511px;
    }
}

.clickable {
    cursor: pointer;
}

.light-blue-bg {
    border-radius: 24px;
    background: #eff6fb;
    padding: 12px;
}

.ftc-phone-input-form input {
    border: none;
    outline: none;
}

@font-face {
    font-family: 'Material Symbols Outlined';
    font-style: normal;
    font-weight: 100 400;
    src: url('https://fonts.gstatic.com/s/materialsymbolsoutlined/v122/kJEPBvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oDMzBwG-RpA6RzaxHMPdY40KH8nGzv3fzfVJO1Q.woff2')
        format('woff2');
}

@font-face {
    font-family: Gilroy-Bold;
    font-style: normal;
    font-weight: normal;
    src:
        local('Gilroy-Bold ☞'),
        url('./assets/Gilroy-Bold.woff') format('woff');
}

.material-symbols-outlined {
    --font-size-lg: 40px;
    --font-size: 24px;
    --font-size-xs: 16px;
    --font-size-xxs: 12px;

    font-size: var(--font-size);
    font-variation-settings:
        'FILL' 0,
        'wght' 400,
        'GRAD' 0,
        'opsz' 48;

    &.fill {
        font-variation-settings:
            'FILL' 1,
            'wght' 400,
            'GRAD' 0,
            'opsz' 48;

        &.bold {
            font-variation-settings:
                'FILL' 1,
                'wght' 700,
                'GRAD' 100,
                'opsz' 40;
        }
    }

    &.bold {
        font-weight: 700;
        font-variation-settings:
            'FILL' 0,
            'wght' 700,
            'GRAD' 100,
            'opsz' 40;
    }

    &.fs-xxs {
        font-size: var(--font-size-xxs);
    }

    &.fs-xs {
        font-size: var(--font-size-xs);
    }

    &.fs-lg {
        font-size: var(--font-size-lg);
    }
}

.break-all-word {
    word-break: break-word;
}

.collapse-toggle-btn {
    position: relative;

    &::after {
        /* stylelint-disable font-family-no-missing-generic-family-keyword */
        font-family: 'Material Symbols Outlined';
        content: '\e5c5';
        display: inline-block;
        padding-left: 4px;
        vertical-align: middle;
    }

    &.active::after {
        content: '\e5c7';
    }

    &.disabled {
        cursor: unset;
        color: var(--gray-gray-400, #cbd5e0);
    }
}
