label,
.col-form-label {
    font-size: var(--ftc-input-font-size, 14px);
    font-style: normal;

    // confirmed with designer, label default is 500 weight, if you need bolder font, please append fw-bold class on label
    font-weight: 500;
    line-height: 16px;
}

.invalid-feedback {
    margin: 0;
    display: inline-block;
    color: var(--bs-form-invalid-color);
    padding-left: 4px;
}

.warning-feedback {
    margin: 0;
    display: inline-block;
    color: var(--bs-warning);
    padding-left: 4px;
}

.wanring {
    color: var(--bs-warning);
}

.info-feedback {
    margin: 0;
    display: inline-block;
    color: var(--bs-info);
    padding-left: 4px;
}

.info {
    color: var(--bs-info);
}

@mixin is-invaild {
    border-radius: var(--ftc-input-border-radius, 4px);
    border: 2px solid var(--tertiary-red-50);
    background: #fce9e9;

    ~ .invalid-feedback {
        font-size: var(--ftc-input-font-size, 14px);
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
    }
}

input,
.form-control {
    &::placeholder {
        color: var(--primary-black-50, #757575);
    }
}

textarea.form-control {
    height: 205px;
}

.form-control {
    --border-radius: var(--ftc-input-border-radius, 4px);

    display: flex;
    height: 40px;
    padding: 8px;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border: 1px solid var(--primary-black-80, #c7c7c7);
    border-radius: var(--border-radius);
    background: var(--white);
    color: var(--ftc-input-text);
    font-weight: 400;
    outline: none;

    &:focus {
        background: inherit;
    }

    &:active {
        background: inherit;
    }

    &:disabled,
    &[readonly] {
        color: var(--primary-black-60, #8f8f8f);
        border: 1px solid var(--primary-black-80, #c7c7c7);
        background: var(--primary-black-95, #f2f2f2);
    }

    &.is-invalid {
        @include is-invaild;
    }

    .invalid-feedback,
    .warning-feedback {
        padding-left: 4px;
    }
}

@mixin error-group {
    display: flex;
    color: var(--tertiary-red-50);
    padding-top: 4px;
    align-items: flex-start;

    span::first-letter {
        text-transform: uppercase;
    }
}

// input
.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;

    + .error-group {
        @include error-group;
    }

    .input-group-append {
        display: flex;
        align-items: center;
        padding-left: 12px;

        .input-group-text {
            font-size: var(--ftc-input-font-size, 14px);
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.25px;
        }
    }
}

.form-select {
    // this icon is assigned from designer
    --bs-form-select-bg-img: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_3169_87795)'%3E%3Cpath d='M8.70956 11.71L11.2996 14.3C11.6896 14.69 12.3196 14.69 12.7096 14.3L15.2996 11.71C15.9296 11.08 15.4796 10 14.5896 10H9.40956C8.51956 10 8.07956 11.08 8.70956 11.71Z' fill='%23323232'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_3169_87795'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");

    font-size: var(--ftc-input-font-size, '14px');
    font-weight: 400;
    line-height: 1.428em;
    color: var(--ftc-input-text);
    background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
    background-size: unset;
    border: 1px solid var(--primary-black-80, #c7c7c7);
    border-radius: var(--ftc-input-border-radius, 4px);
    background-color: var(--white);
    padding: 10px 16px;
    outline: none;

    option {
        background: var(--white);
    }
}

// error message
.form-error-message {
    &.error-group {
        @include error-group;
    }
}
