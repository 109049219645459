.dropdown-menu {
    /* stylelint-disable-next-line color-function-notation */
    --bs-dropdown-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 25%);
    --bs-dropdown-border-radius: 8px;
    --bs-dropdown-border-width: 0px;
    --bs-dropdown-border-color: var(--white, '#fff');
    --bs-dropdown-font-size: inherit;
    --bs-dropdown-padding-x: 0;
    --bs-dropdown-padding-y: 0;
    --bs-dropdown-color: inherit;
    --bs-dropdown-divider-bg: #ccd1d2;

    box-shadow: var(--bs-dropdown-box-shadow);

    &.action-dropdown-menu {
        /* stylelint-disable-next-line color-function-notation */
        --bs-dropdown-box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 20%);

        padding: 12px 0;

        .btn-link {
            padding: 4px 12px;
            border-radius: 0;

            &:hover {
                background-color: #efeef1;
            }
        }
    }
}

.dropdown-divider {
    &.transparent {
        margin: 0;
        border: none;
        opacity: 0;
    }
}
