// Override options: https://github.com/twbs/bootstrap/blob/main/scss/_variables.scss

/* stylelint-disable-next-line scss/dollar-variable-colon-space-after */
$font-family-sans-serif: 'Inter', system-ui, 'Segoe UI', roboto, 'Noto Sans', 'Liberation Sans', arial, sans-serif;

// $font-size-root affects the value of `rem`, which is used for as well font sizes, paddings, and margins
// $font-size-base affects the font size of the body text
$font-size-base: 1rem !default;

// $font-size-lg: $font-size-base * 1.43 !default;
$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semibold: 600 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;
$font-weight-base: $font-weight-medium !default;

// Navs
$nav-link-font-size: var(--ftc-base-font-size);
$nav-link-font-weight: 600;
$nav-link-color: #49454f;
$nav-link-hover-color: #1d1b20;
$nav-tabs-link-active-color: #1d1b20;
$nav-tabs-link-active-bg: transparent;
$nav-tabs-link-active-border-color: #2b7ce1;
$nav-tabs-border-color: #e6e0e9;
// based on Wenwen's design, it's 20 (14*1.4285)
// @TBD design is always changing
// $line-height-base: 1.8 !default;
// $line-height-sm:              1.25 !default;
// $line-height-lg:              2 !default;
$input-btn-padding-y-lg: 12px !default;
$input-btn-padding-x-lg: 14px !default;
$input-btn-font-size-lg: $font-size-base !default;
$btn-padding-y-lg: $input-btn-padding-y-lg !default;
$btn-padding-x-lg: $input-btn-padding-x-lg !default;
$btn-font-size-lg: $font-size-base !default;
$btn-border-width: 2px !default;
$btn-border-radius-lg: 32px;
$btn-font-weight: 600;

// scss-docs-start spacer-variables-maps
$spacer: 1rem; // based on the font-size property on html
$spacers: (
    // 0px
    0: 0,

    // 4
    1: $spacer * 0.25,

    // 8px
    2: $spacer * 0.5,

    // 12px
    3: $spacer * 0.75,

    // 16px
    4: $spacer,

    // 24px
    5: $spacer * 1.5,

    // 32px
    6: $spacer * 2,

    // 48px
    7: $spacer * 3,

    // 64px
    8: $spacer * 4
);

$grid-gutter-width: 1.5rem;
$gutters: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  // 64px
  6: $spacer * 4
);

$breadcrumb-divider: quote("/");

$utilities: (
  "opacity": (
    property: opacity,
    values: (
        // feel free to add more cases
        0: 0,
        25: .25,
        50: .5,
        70: .7,
        75: .75,
        100: 1,
    )
  )
);