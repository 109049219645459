.badge {
    --bs-badge-padding-x: 8px;
    --bs-badge-padding-y: 4px;
    --bs-badge-font-size: 14px;
    --bs-badge-font-weight: 500;
    --bs-badge-color: #1d192b;
    --bs-badge-border-radius: 8px;
    /* stylelint-disable-next-line declaration-empty-line-before */
    padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
    font-size: var(--bs-badge-font-size);
    font-weight: var(--bs-badge-font-weight);
    line-height: 20px;
    color: var(--bs-badge-color);
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: var(--bs-badge-border-radius);
    background: var(--secondary-blue-95, #d3e7ff);
    display: inline-flex;
    gap: 8px;
    align-items: center;

    &[type='button'] {
        border: none;

        &:hover {
            background: var(--secondary-blue-95, #d3e7ff);
        }

        &:focus {
            background: var(--secondary-blue-95, #d3e7ff);
        }
    }

    .badge-inner-icon {
        all: unset;
        color: #1d192b;
        vertical-align: middle;
        display: inline-flex;

        &.badge-inner-icon-remove {
            font-size: 18px;
            line-height: 18px;
        }

        &:disabled {
            color: var(--bs-btn-disabled-color);
            pointer-events: none;
            cursor: default;
            opacity: var(--bs-btn-disabled-opacity);
        }
    }

    &.disabled {
        color: var(--primary-black-60, #8f8f8f);
        border: 1px solid var(--primary-black-80, #c7c7c7);
        background: var(--primary-black-95, #f2f2f2);
    }
}
