// override basic color helper
// ref https://getbootstrap.com/docs/5.2/utilities/colors/#colors

.text-primary {
    color: var(--secondary-blue-50) !important;
}

.text-danger {
    color: var(--tertiary-red-50) !important;
}

.text-success {
    color: var(--highlight-green-50) !important;
}

.text-white {
    color: var(--white) !important;
}

.text-secondary {
    color: var(--primary-black-50) !important;
}

.text-body {
    color: var(--primary-black-0) !important;
}

.text-warning {
    color: #d16002 !important;
}
