:root {
    --white: #fff;

    // Primary
    --primary-black-0: #000;
    --primary-black-10: #1f1f1f;
    --primary-black-20: #303030;
    --primary-black-30: #474747;
    --primary-black-40: #5e5e5e;
    --primary-black-50: #757575;
    --primary-black-60: #8f8f8f;
    --primary-black-70: #ababab;
    --primary-black-80: #c7c7c7;
    --primary-black-90: #e3e3e3;
    --primary-black-95: #f2f2f2;
    --primary-black-99: #fdfcfb;

    // Secondary
    --secondary-blue-0: #003475;
    --secondary-blue-10: #0148a1;
    --secondary-blue-20: #0253b9;
    --secondary-blue-30: #0d60c8;
    --secondary-blue-40: #186cd6;
    --secondary-blue-50: #2b7ce1;
    --secondary-blue-60: #3689f1;
    --secondary-blue-70: #4899ff;
    --secondary-blue-80: #69acff;
    --secondary-blue-90: #94c3ff;
    --secondary-blue-95: #d3e7ff;
    --secondary-blue-99: #f7fcff;

    // Tertiary
    --tertiary-red-0: #000;
    --tertiary-red-10: #410e0b;
    --tertiary-red-20: #601410;
    --tertiary-red-30: #8c1d18;
    --tertiary-red-40: #b3261e;
    --tertiary-red-50: #d8281c;
    --tertiary-red-60: #e46962;
    --tertiary-red-70: #ec928e;
    --tertiary-red-80: #f2b8b5;
    --tertiary-red-90: #f9dedc;
    --tertiary-red-95: #fceeee;
    --tertiary-red-99: #fffbf9;

    // Shades
    --shades-navy-0: #161e2b;
    --shades-navy-10: #202a3b;
    --shades-navy-20: #273144;
    --shades-navy-30: #2c374b;
    --shades-navy-40: #344055;
    --shades-navy-50: #414e66;
    --shades-navy-60: #54627b;
    --shades-navy-70: #6a7993;
    --shades-navy-80: #818fa8;
    --shades-navy-90: #a1adc1;
    --shades-navy-95: #c0cada;
    --shades-navy-99: #e7edf6;

    // Highlight
    --highlight-green-0: #006536;
    --highlight-green-10: #037c44;
    --highlight-green-20: #0f9657;
    --highlight-green-30: #1aa062;
    --highlight-green-40: #26aa6d;
    --highlight-green-50: #34b67a;
    --highlight-green-60: #45cb8d;
    --highlight-green-70: #54dd9e;
    --highlight-green-80: #69f4b4;
    --highlight-green-90: #80ffc4;
    --highlight-green-95: #baffdf;
}
