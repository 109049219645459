.swal2-show,
.swal2-hide {
    animation: unset;
}

div:where(.swal2-icon) {
    margin: 0.5em auto 2.5em;
}

div:where(.swal2-container).swal2-backdrop-show,
div:where(.swal2-container).swal2-noanimation {
    background-color: rgb(0 0 0 / 50%);
}

div:where(.swal2-container) div:where(.swal2-popup) {
    border-radius: var(--ftc-modal-border-radius);
    padding: var(--ftc-modal-padding-y) var(--ftc-modal-padding-x);
    color: inherit;
    font-family: inherit;
    font-size: inherit;
}

div:where(.swal2-container) h2:where(.swal2-title) {
    display: block;
    color: inherit;
    font-family: inherit;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    margin: 0 0 16px;
    padding: 0;
}

div:where(.swal2-container) .swal2-html-container {
    display: block;
    margin: 0;
    padding: 0;
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    text-align: left;
}

div:where(.swal2-container) button:where(.swal2-close) {
    display: flex;
    position: absolute;
    top: 16px;
    right: 16px;
    width: 24px;
    height: 24px;
    color: var(--primary-black-70, #ababab);

    &:hover {
        transform: none;
        /* stylelint-disable-next-line alpha-value-notation */
        background: transparent;
        color: var(--primary-black-70, #ababab);
    }
}

div:where(.swal2-container) button:where(.swal2-close):focus {
    box-shadow: none;
}

div:where(.swal2-container) div:where(.swal2-actions) {
    margin: var(--ftc-modal-padding-y) 0 0 0;
    gap: 0.75rem;
    justify-content: flex-end;
}
